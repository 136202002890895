import React, { forwardRef } from "react";

import Loader from "@components/Loader/Loader";

import "./Button.scss";

const Button = forwardRef(
    (
        { children, variant, size, type = "primary", onClick, loading, isLink = false, theme, ...rest },
        forwardedRef
    ) => {
        const ContainerComponent = isLink ? "a" : "button";

        return (
            <ContainerComponent
                className="sc-button"
                data-variant={variant}
                data-type={type}
                data-size={size}
                data-loading={loading}
                data-theme={theme}
                onClick={onClick}
                ref={forwardedRef}
                {...rest}
            >
                <div className="button-content">{children}</div>
                <div className="loader-container">
                    <Loader theme={variant === "primary" ? "dark" : "light"} />
                </div>
            </ContainerComponent>
        );
    }
);

Button.displayName = "Button";

export default Button;
