import { v4 as uuidv4 } from "uuid";

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function isInTriangle(px, py, ax, ay, bx, by, cx, cy) {
    //credit: http://www.blackpawn.com/texts/pointinpoly/default.html

    var v0 = [cx - ax, cy - ay];
    var v1 = [bx - ax, by - ay];
    var v2 = [px - ax, py - ay];

    var dot00 = v0[0] * v0[0] + v0[1] * v0[1];
    var dot01 = v0[0] * v1[0] + v0[1] * v1[1];
    var dot02 = v0[0] * v2[0] + v0[1] * v2[1];
    var dot11 = v1[0] * v1[0] + v1[1] * v1[1];
    var dot12 = v1[0] * v2[0] + v1[1] * v2[1];

    var invDenom = 1 / (dot00 * dot11 - dot01 * dot01);

    var u = (dot11 * dot02 - dot01 * dot12) * invDenom;
    var v = (dot00 * dot12 - dot01 * dot02) * invDenom;

    return u >= 0 && v >= 0 && u + v < 1;
}

export function throttle(callback, limit) {
    var tick = false;

    return function (e) {
        if (!tick) {
            callback(e);
            tick = true;
            setTimeout(function () {
                tick = false;
            }, limit);
        }
    };
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function throttleDebounce(callback, limit) {
    const throttleCallback = throttle(callback, limit);
    const debounceCallback = debounce(callback, limit);
    return (e) => {
        throttleCallback(e);
        debounceCallback(e);
    };
}
/**
 * Generates a hash using a supplied string and salt. This is mainly meant for use in components where
 * the str would be a value (doesn't need to be unique across all components) and the salt would be an
 * ID unique to the component (use @reach/useId for this)
 * @param {string} str A string value of any length
 * @param {string} salt Another string that should be unique to a given component
 */
export function makeHash(str, salt) {
    let hash = 0;
    let saltedStr = salt ? `${str}__${salt}` : str;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < saltedStr.length; i++) {
        var char = saltedStr.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash.toString();
}

export function wrapEvent(theirHandler, ourHandler) {
    return (event) => {
        theirHandler && theirHandler(event);
        if (!event.defaultPrevented) {
            return ourHandler(event);
        }
    };
}

// This exists so I can easily redirect if no beta access key is present on initial load. Nextjs does
// provide the query params with the router object but only after hydration which doesn't work for initial load
export function parseQueryParams(path) {
    const queryString = path.split(/\?/)[1];

    if (!queryString) return {};

    return queryString.split("&").reduce((parsedQuery, queryParam) => {
        const [queryParamName, queryParamValue] = queryParam.split("=");
        return { ...parsedQuery, [queryParamName]: queryParamValue };
    }, {});
}

/**
 * Generates a 16 character long cryptographically-strong random value that can be
 * used to assign IDs to entities that need to be stored in a DB.
 */
export function generateEntityId() {
    const uniqueId = uuidv4();

    return uniqueId.replace(/-/g, "").substring(0, 16).toUpperCase();
}

export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

export function repeatWhile(fn, cond) {
    return function () {
        let count = 0;
        let newCount = (count += 1);
        while (cond(count++)) fn(...arguments, count);
    };
}

export function repeatTimes(fn, n) {
    return function () {
        while (n--) fn(...arguments);
    };
}
